import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import { getResizeImgUrl, timestampToTime } from '../../util/format'
import { category } from '../../config/category'
import CalendarHeatmap from 'react-calendar-heatmap'
import ReactTooltip from 'react-tooltip'
import loadable from '@loadable/component'

const DisplayAdsense = loadable(() => import('../Adsense/DisplayAdsense'))
// const Twitter = loadable(() => import('../Twitter/Twitter'))

const Side = (props: {
  category: any
  location: Array<string>
  articleId: string
  rankingBottomPC: string
  rankingBottomMobile: string
  betweenSelfAndCategoryLIstPC: string
  betweenSelfAndCategoryLIstMobile: string
  sideFixedPC: string
  sideFixedMobile: string
}): JSX.Element => {
  // 参考 : https://zenn.dev/sunaoproducts/articles/1818c48f60dc81
  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const data = useStaticQuery(
    graphql`
      query {
        allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
          edges {
            node {
              category
              id
              thumbnail
              title
              createdAt {
                seconds
              }
            }
          }
        }
        allRanking {
          edges {
            node {
              id
              category
              title
              thumbnail
            }
          }
        }
      }
    `
  )

  const rankingArticleIdList: Array<string> = []
  for (let i = 0; i < data.allRanking.edges.length; i++) {
    rankingArticleIdList.push(data.allRanking.edges[i].node.id)
  }

  // 新着記事一覧用配列
  const articles: Array<{
    node: {
      id: string
      thumbnail: string
      title: string
      category: string
    }
  }> = []
  // 新着カテゴリー記事一覧用配列
  const categoryArticles: Array<{
    node: {
      id: string
      thumbnail: string
      title: string
      category: string
    }
  }> = []
  // 新着カテゴリー記事ID格納用。新着記事一覧を選定するときに、重複チェックするために利用する。
  const categoryArticlesId: Array<string> = []
  // props.location.length : 記事詳細ページ以上で表示する。
  if (data && data.allArticle && props.location.length > 3) {
    // 表示上限
    const max = 5
    // 新着カテゴリー記事一覧の生成
    for (let i = 0; i < data.allArticle.edges.length; i++) {
      // 同じ記事は新着記事として表示しない。
      if (data.allArticle.edges[i].node.id === props.articleId) {
        continue
      }
      // ランキング記事の場合は、表示しない。
      if (rankingArticleIdList.includes(data.allArticle.edges[i].node.id)) {
        continue
      }
      // 同じカテゴリーの単語を含む、新着記事を取得する。
      if (data.allArticle.edges[i].node.title.includes(props.category.value)) {
        categoryArticles.push(data.allArticle.edges[i])
        categoryArticlesId.push(data.allArticle.edges[i].node.id)
      }
      // 最大記事数を超えたらbreakする。
      if (max === categoryArticles.length) {
        break
      }
    }

    // 新着記事一覧の生成
    for (let i = 0; i < data.allArticle.edges.length; i++) {
      // 同じ記事は新着記事として表示しない。
      if (data.allArticle.edges[i].node.id === props.articleId) {
        continue
      }

      // ランキング記事の場合は、表示しない。
      if (rankingArticleIdList.includes(data.allArticle.edges[i].node.id)) {
        continue
      }

      // 新着カテゴリー記事一覧に含まれる記事は表示しない。
      if (categoryArticlesId.includes(data.allArticle.edges[i].node.id)) {
        continue
      }

      // 同じカテゴリーの単語を含む、新着記事を取得する。
      if (data.allArticle.edges[i].node.title.includes(props.category.value)) {
        articles.push(data.allArticle.edges[i])
      }

      // 最大記事数を超えたらbreakする。
      if (max === articles.length) {
        break
      }
    }
  }

  const disCategory = category.filter((cate: { name: string; id: number; value: string }) => cate.name !== 'None')

  // 参考 : https://maku77.github.io/js/time/date-to-string.html
  const date = new Date()
  const today = date.toString()
  date.setMonth(date.getMonth() - 4)
  const fourMonthsAgo = date.toString()

  const createDateList:
    | Array<{
        date: string
        count: number
      }>
    | undefined = []
  for (let i = 0; i < data.allArticle.edges.length; i++) {
    const createDate = timestampToTime(data.allArticle.edges[i].node.createdAt.seconds)

    // 参考 : https://qiita.com/risagon/items/a885704a816540a9e58e
    if (createDateList.findIndex(({ date }) => date === createDate) === -1) {
      createDateList.push({
        date: createDate,
        count: 1,
      })
    } else {
      createDateList.find((el) => el.date == createDate).count += 1
    }
  }

  return (
    <aside className="side__side">
      <section className="side__widget">
        {props.location && props.location.length > 2 && (
          <p className="side__widget--title">
            <span>KURORO BLOG について</span>
          </p>
        )}

        {props.location && props.location.length <= 2 && (
          <h3 className="side__widget--title">
            <span>KURORO BLOG について</span>
          </h3>
        )}

        <div className="side__widget--contents">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=08679d84-12bf-4d77-aeb4-bac7f3f40f8d"
            width={120}
            height={120}
            alt="KURORO BLOGのロゴ"
            referrerPolicy="no-referrer"
          />
          <dl className="side__widget--word">
            <dt className="side__widget--wordTitle">KURORO BLOG</dt>
            <dd className="side__widget--wordDesc">
              「モノづくりから始まるエンジニア」をコンセプトに、コンテンツ発信を行っております。
              エンジニア初学者やプログラミングに関心のある方々におすすめです。
            </dd>
          </dl>
        </div>

        <div className="side__widget--sns">
          <a
            href="https://github.com/kuroroblog"
            rel="noopener noreferrer"
            target="_blank"
            className="side__widget--snsIcon"
            aria-label="githubへのリンク"
          >
            <svg viewBox="0 0 27 27" width="24" height="24">
              <path
                fill="currentColor"
                d="M13.4 1.2C7 1 1.8 6 1.7 12.4v.5c0 5.1 3.2 9.8 8.2 11.5.6.1.7-.2.7-.6v-2.9s-3.3.6-4-1.5c0 0-.6-1.3-1.3-1.8 0 0-1.1-.7.1-.7.7.1 1.5.6 1.8 1.2.6 1.2 2.2 1.7 3.4 1h.1c.1-.6.4-1.2.7-1.6-2.7-.4-5.4-.6-5.4-5.2 0-1.1.5-2.1 1.2-2.8 0-1.1 0-2.2.3-3.2 1-.4 3.3 1.3 3.3 1.3 2-.6 4-.6 6 0 0 0 2.2-1.6 3.2-1.2.5 1 .5 2.2.1 3.2.7.7 1.2 1.8 1.2 2.8 0 4.5-2.8 5-5.5 5.2.6.6.9 1.3.7 2.2v4c0 .5.2.6.7.6 4.9-1.7 8.2-6.2 8-11.5.1-6.4-5.1-11.6-11.6-11.6-.1-.1-.2-.1-.2-.1z"
              ></path>
            </svg>
          </a>

          <a
            href="https://twitter.com/KURORO59126227"
            rel="noopener noreferrer"
            target="_blank"
            className="side__widget--snsIcon"
            aria-label="twitterへのリンク"
          >
            <svg viewBox="0 0 27 27" width="24" height="24">
              <path
                fill="currentColor"
                d="M23.1 8.7v.7c0 8-6.4 14.5-14.4 14.6h-.2C5.7 24 3 23.2.6 21.7c.4 0 .8.1 1.2.1 2.3 0 4.6-.8 6.3-2.1C6 19.6 4 18.2 3.3 16c.3.1.7.1 1 .1.5 0 .9-.1 1.4-.2-2.4-.5-4.1-2.6-4.1-5.1v-.1c.7.4 1.5.6 2.3.7-1.5-1-2.2-2.5-2.2-4.3 0-.9.2-1.8.7-2.6C5 7.8 8.8 9.7 13 9.9c-.1-.4-.1-.8-.1-1.2 0-2.8 2.2-5.2 5.2-5.2 1.5 0 2.8.6 3.8 1.7C23 5 24 4.6 25 4.1c-.4 1.2-1.2 2.1-2.2 2.8 1-.1 2-.4 2.9-.8-.8 1-1.7 1.8-2.6 2.6z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.wantedly.com/id/kurokuroro"
            rel="noopener noreferrer"
            target="_blank"
            className="side__widget--snsIcon"
            aria-label="wantedlyへのリンク"
          >
            <svg id="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 394" width="24" height="24">
              <defs>
                <style>{`.cls-1{fill:#21bddb;}.cls-2{fill:#282828;fill-rule:evenodd;}`}</style>
              </defs>
              <circle className="cls-1" cx="375" cy="122.95" r="38.98" />
              <path
                className="cls-2"
                d="M217.17,234.77c-2.34-1.52-9-10.45-27.42-54.44-1.15-2.76-2.22-5.14-3.23-7.18l-3.77-9.08L150.47,86.12H85.89l32.29,77.95,32.29,78,29.82,72a2.68,2.68,0,0,0,4.94,0l32.45-77.68A1.34,1.34,0,0,0,217.17,234.77Z"
              />
              <path
                className="cls-2"
                d="M338.15,234.77c-2.34-1.52-9-10.45-27.42-54.44-1.15-2.76-2.23-5.14-3.24-7.19l-3.75-9.07L271.45,86.12H206.87l32.29,77.95,32.29,78,29.82,72a2.68,2.68,0,0,0,4.94,0l32.45-77.67A1.36,1.36,0,0,0,338.15,234.77Z"
              />
            </svg>
          </a>

          <a
            href="https://atcoder.jp/users/kuroroblog"
            rel="noopener noreferrer"
            target="_blank"
            className="side__widget--snsIcon"
            aria-label="Atcoderへのリンク"
          >
            <svg viewBox="0 0 27 27" width="24" height="24">
              <path
                fill="currentColor"
                d="M9.6 23.9c-3.6 0-6.5-3-6.5-6.6 0-1.7.7-3.4 1.9-4.6l2.3-2.3c.5-.4 1.2-.4 1.6.1.4.4.4 1 0 1.5l-2.3 2.3c-1.7 1.7-1.7 4.4 0 6.1s4.4 1.7 6.1 0l2.3-2.3c.5-.4 1.2-.4 1.6.1.4.4.4 1 0 1.5L14.3 22c-1.3 1.2-2.9 1.9-4.7 1.9zm1-6.4c-.6 0-1.1-.5-1.1-1.1 0-.3.1-.6.3-.8l5.8-5.8c.4-.4 1.1-.4 1.6 0 .4.4.4 1.1 0 1.6l-5.8 5.8c-.2.2-.5.3-.8.3zm8.3-.6c-.3 0-.6-.1-.8-.3-.4-.4-.4-1.1 0-1.6l2.3-2.3c1.7-1.7 1.7-4.4 0-6.1-1.7-1.7-4.4-1.7-6.1 0L12 8.9c-.5.4-1.2.4-1.6-.1-.4-.4-.4-1 0-1.5L12.7 5c2.6-2.6 6.7-2.6 9.2 0s2.6 6.7 0 9.2l-2.3 2.4c-.2.2-.5.3-.7.3z"
              ></path>
            </svg>
          </a>
        </div>
      </section>
      <section className="side__contents">
        <div className="side__titleSection">
          <div className="side__titleSectionIn">
            <p className="side__h3">筆者のプロフィール</p>
          </div>
        </div>

        <div className="side__articleAuthorProfile">
          <div className="side__articleAuthorProfile--image">
            <img
              alt="黒光俊秀の画像"
              width={120}
              height={120}
              src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image.webp?alt=media&token=620a5e85-cd7d-4157-9820-e21b8cf9f613"
              className="side__articleAuthorProfile--imageImg"
            />
            <noscript>
              <style>{`.lazyload-placeholder { display: none; }`}</style>
              <img
                alt="黒光俊秀の画像"
                width={120}
                height={120}
                src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image.webp?alt=media&token=620a5e85-cd7d-4157-9820-e21b8cf9f613"
                className="side__articleAuthorProfile--imageImg"
              />
            </noscript>
            <p>
              KURORO / 黒光俊秀
              <span>KURORO BLOG 運営者 & 執筆者</span>
            </p>
          </div>

          <p className="side__articleAuthorProfile--desc">
            <strong>【経歴】</strong>
            <br />
            ・2017年8月~2020年6月 株式会社終活ねっとのインターン ⏩ 正社員として勤務。
            <strong>メディア事業部のエンジニア責任者</strong>としてサービス運営を行い、
            <a href="https://webtan.impress.co.jp/e/2020/04/16/35648">月間1000万PVを達成。</a>
            <br />
            また2018年10月12日に、
            <a href="https://jp.techcrunch.com/2018/11/12/dmm-syukatsunet/">M&AによりDMMグループ入り</a>
            を果たしました。
            <br />
            ・現在は <strong>KURORO BLOG 運営者 & 執筆者</strong>として活動中。
            <br />
            <strong>【運営者コメント】</strong>
            <br />
            <a href="/python/">Python</a>に関してKURORO
            BLOGを通じて、わかりやすくお伝えしてまいります。記事に関してわかりにくい点や質問等ございましたら、
            <a href="/contacts/user/">こちら</a>よりご連絡ください。
            <strong>一人でも多くのエンジニアが現れますように</strong>
            <br />
            <strong style={{ marginTop: 4, display: 'inline-block' }}>
              <a href="/profile/">プロフィール詳細</a>
            </strong>
          </p>
        </div>
      </section>
      <section className="side__contents">
        <form action="/search/" className="side__search">
          <input className="side__search--input" type="search" name="q" placeholder="検索" autoComplete="on" />
          <input className="side__search--inputBtn" type="submit" value="" />
        </form>
      </section>
      {data.allRanking.edges.length > 0 && (
        <>
          <section className="side__contents">
            <div className="side__titleSection">
              <div className="side__titleSectionIn">
                {props.location && props.location.length > 2 && (
                  <>
                    <p className="side__h3">人気の記事ランキング</p>
                    <p className="side__p">初学者へよく読まれる記事</p>
                  </>
                )}
                {props.location && props.location.length <= 2 && (
                  <>
                    <h3 className="side__h3">人気の記事ランキング</h3>
                    <p className="side__p">初学者へよく読まれる記事</p>
                  </>
                )}
              </div>
            </div>

            {data.allRanking.edges.map(
              (
                article: {
                  node: {
                    id: string
                    thumbnail: string
                    title: string
                    category: string
                  }
                },
                idx: number
              ) => {
                const img = (
                  <img
                    alt={article.node.title}
                    className="side__img"
                    src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                    srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                    referrerPolicy="no-referrer"
                    width={60}
                    height={60}
                  />
                )
                return (
                  <article className="side__articleContent" key={idx}>
                    <a href={'/' + article.node.category + '/' + article.node.id + '/'} className="side__a">
                      <div className="side__thumb">
                        <LazyLoad>{img}</LazyLoad>
                        <noscript>
                          <style>{`.lazyload-placeholder { display: none; }`}</style>
                          {img}
                        </noscript>
                        <span className="side__icon">{idx + 1}</span>
                      </div>
                      <div className="side__text">
                        <p className="side__title">{article.node.title}</p>
                      </div>
                    </a>
                  </article>
                )
              }
            )}
          </section>
        </>
      )}
      {/* <Twitter location={props.location} /> */}
      <section className="side__contents">
        {props.location && props.location.length > 2 && (
          <p className="side__widget--title">
            <span>ブログ記事 - 投稿数</span>
          </p>
        )}

        {props.location && props.location.length <= 2 && (
          <h3 className="side__widget--title">
            <span>ブログ記事 - 投稿数</span>
          </h3>
        )}
        <div className="side__CalendarHeatmap">
          <CalendarHeatmap
            startDate={fourMonthsAgo}
            endDate={today}
            values={createDateList}
            showOutOfRangeDays={true}
            tooltipDataAttrs={(value: { date: string; count: number }) => {
              // Temporary hack around null value.date issue
              if (!value || !value.date) {
                return null
              }
              return {
                'data-tip': `${value.count} posts ${value.date}`,
              }
            }}
            classForValue={(value) => {
              if (!value) {
                return 'color-empty'
              }
              if (value.count > 4) {
                value.count = 4
              }
              return `color-github-${value.count}`
            }}
          />
          <ReactTooltip />
        </div>
      </section>
      <section className="side__contents">
        {props.location && props.location.length > 2 && <p className="side__categoryList">カテゴリから探す</p>}
        {props.location && props.location.length <= 2 && <h3 className="side__categoryList">カテゴリから探す</h3>}

        <ul className="side__categoryList--ul">
          {disCategory.map(
            (
              category: {
                name: string
                value: string
                children: Array<{
                  name: string
                  value: string
                }>
              },
              idx: number
            ) => {
              return (
                <>
                  <li className="side__categoryList--li" key={idx}>
                    <a className="side__categoryList--a" href={'/' + category.name + '/'}>
                      {category.value}
                    </a>
                  </li>
                  {category.children.length > 0 && (
                    <ul className="side__categoryList--nest">
                      {category.children.map((child: { name: string; value: string }) => {
                        return (
                          <li className="side__categoryList--li" key={idx}>
                            <a className="side__categoryList--nestA" href={'/' + child.name + '/'}>
                              {child.value}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </>
              )
            }
          )}
        </ul>
      </section>
      {articles.length > 0 && (
        <>
          <section className="side__contents">
            <div className="side__titleSection">
              <div className="side__titleSectionIn">
                <p className="side__h3">新着記事</p>
                <p className="side__p">新しく発信された記事</p>
              </div>
            </div>

            {articles.map(
              (
                article: {
                  node: {
                    id: string
                    thumbnail: string
                    title: string
                    category: string
                  }
                },
                idx: number
              ) => {
                const img = (
                  <img
                    alt={article.node.title}
                    className="side__img"
                    src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                    srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                    referrerPolicy="no-referrer"
                    width={60}
                    height={60}
                  />
                )
                return (
                  <article className="side__articleContent" key={idx}>
                    <a href={'/' + article.node.category + '/' + article.node.id + '/'} className="side__a">
                      <div className="side__thumb">
                        <LazyLoad>{img}</LazyLoad>
                        <noscript>
                          <style>{`.lazyload-placeholder { display: none; }`}</style>
                          {img}
                        </noscript>
                        <span className="side__icon">{idx + 1}</span>
                      </div>
                      <div className="side__text">
                        <p className="side__title">{article.node.title}</p>
                      </div>
                    </a>
                  </article>
                )
              }
            )}
          </section>

          <DisplayAdsense
            slot={{
              PC: '8816587778',
              Mobile: '7503506107',
            }}
            position={{
              PC: {
                top: 16,
                bottom: 16,
                left: 8,
                right: 16,
              },
              Mobile: {
                top: 16,
                bottom: 16,
                left: 0,
                right: 0,
              },
            }}
            type={{
              PC: 'display-longHeight',
              Mobile: 'display-auto',
            }}
            isSticky={false}
          />
        </>
      )}
      {categoryArticles.length > 0 && (
        <section className="side__contents side__sticky">
          <div className="side__titleSection">
            <div className="side__titleSectionIn">
              <p className="side__h3">
                <a href={'/' + props.category.name + '/'}>{props.category.value}</a>の新着記事
              </p>
              <p className="side__p">{'新しく発信された' + props.category.value + 'の記事'}</p>
            </div>
          </div>

          {categoryArticles.map(
            (
              article: {
                node: {
                  id: string
                  thumbnail: string
                  title: string
                  category: string
                }
              },
              idx: number
            ) => {
              const img = (
                <img
                  alt={article.node.title}
                  className="side__img"
                  src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                  srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                  referrerPolicy="no-referrer"
                  width={60}
                  height={60}
                />
              )
              return (
                <article className="side__articleContent" key={idx}>
                  <a href={'/' + article.node.category + '/' + article.node.id + '/'} className="side__a">
                    <div className="side__thumb">
                      <LazyLoad>{img}</LazyLoad>
                      <noscript>
                        <style>{`.lazyload-placeholder { display: none; }`}</style>
                        {img}
                      </noscript>
                      <span className="side__icon">{idx + 1}</span>
                    </div>
                    <div className="side__text">
                      <p className="side__title">{article.node.title}</p>
                    </div>
                  </a>
                </article>
              )
            }
          )}

          <div className="side__pageTop" onClick={returnTop}>
            <a>
              <i className="fas fa-chevron-circle-up" aria-hidden="true" /> ページトップへ
            </a>
          </div>

          <DisplayAdsense
            slot={{
              PC: props.sideFixedPC,
              Mobile: props.sideFixedMobile,
            }}
            position={{
              PC: {
                top: 16,
                bottom: 16,
                left: 8,
                right: 0,
              },
              Mobile: {
                top: 16,
                bottom: 16,
                left: 0,
                right: 0,
              },
            }}
            type={{
              PC: 'display-auto',
              Mobile: 'display-auto',
            }}
            isSticky={false}
          />
        </section>
      )}
      {props.location.length <= 3 && (
        <DisplayAdsense
          slot={{
            PC: props.sideFixedPC,
            Mobile: props.sideFixedMobile,
          }}
          position={{
            PC: {
              top: 16,
              bottom: 16,
              left: 8,
              right: 16,
            },
            Mobile: {
              top: 16,
              bottom: 16,
              left: 0,
              right: 0,
            },
          }}
          type={{
            PC: 'display-auto',
            Mobile: 'display-auto',
          }}
          isSticky={true}
        />
      )}
    </aside>
  )
}
export default Side
